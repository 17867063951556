import * as React from 'react'
import { useForm } from 'react-hook-form'

/**
 * ? Local Imports
 */
import { useUpdateAgentTransactionLimit } from 'agents/hooks/useUpdateAgent'

import Box from 'design/elements/Box'
import Button from 'design/elements/Button'
import Checkbox from 'design/elements/Checkbox'
import Spacer from 'design/elements/Spacer'
import Text from 'design/elements/Text'
import TextField, { Label } from 'design/elements/TextField'
import Divider from 'design/elements/Divider'

import { DisableTree } from 'lib/gate/GateComponents'
import { loading } from 'lib/formatters'
import { ShowTree } from 'lib/gate'
import { fetchAgentsTransferLimit } from 'agents/agents.api.client'
import { useQuery } from 'react-query'

const TransactionLimitForm = ({ agent, alert, clearAlert, notify, roles }) => {
  const [updateTransLimit, { status }] = useUpdateAgentTransactionLimit({ alert, clearAlert, notify })
  const { data: transferLimitInfo, isFetching } = useQuery(
    ['agent_transfer_Limit', agent?.agent_id],
    () => fetchAgentsTransferLimit(agent.agent_id),
    {
      enabled: Boolean(agent?.agent_id),
      staleTime: 5 * 60 * 1000,
      notifyOnStatusChange: false,
    }
  )
  const form = useForm({
    mode: 'onBlur',
    defaultValues: Object.assign(
      {},
      {
        upgrade_limit_to_highest: false,
      }
    ),
  })

  const registering = React.useRef(false)
  const [upgrade_limit, allowUpgrade] = React.useState(agent?.upgrade_limit_to_highest)
  React.useEffect(() => {
    if (transferLimitInfo) {
      form.setValue('transaction_limit', transferLimitInfo.daily_transaction_limit)
      form.setValue('transfer_limit', transferLimitInfo.max_transfer_limit)
    }
  }, [transferLimitInfo, form])

  const handleFormSubmit = React.useCallback(
    (_formValues) => {
      const agentId = agent?.agent_id

      const _formBody = Object.assign({}, _formValues, {
        agent_id: +agentId,
        transaction_limit: Number.isFinite(+_formValues.transaction_limit)
          ? +_formValues.transaction_limit
          : +_formValues.transaction_limit,
      })
      updateTransLimit(_formBody)
    },
    [agent, updateTransLimit]
  )

  React.useEffect(() => {
    if (!registering.current) {
      form.register({ name: 'upgrade_limit_to_highest', type: 'custom', required: true })
      form.setValue('upgrade_limit_to_highest', upgrade_limit)
    }
    return () => {
      registering.current = false
    }
  }, [form, upgrade_limit])

  return (
    <Box as="form" onSubmit={form.handleSubmit(handleFormSubmit)}>
      <Spacer mt="lg" />
      <Text textTransform="uppercase" fontWeight="bold">
        Transactions information
      </Text>
      <Divider />

      <DisableTree forRoles={[roles.POS_TEAM, roles.SERVICE_OWNER]}>
        <TextField
          label="Transfer Limit"
          name="transfer_limit"
          disabled={true}
          error={form.errors?.transfer_limit}
          helperText={form.errors?.transfer_limit && 'Please enter a numeric value'}
          ref={form.register({ required: false, pattern: { value: /[\d]+$/ } })}
        />

        <Spacer mt="lg" />
        <TextField
          label="Transaction Limit"
          name="transaction_limit"
          error={form.errors?.transaction_limit}
          helperText={form.errors?.transaction_limit && 'Please enter a numeric value'}
          ref={form.register({ required: false, pattern: { value: /[\d]+$/ } })}
        />
        <Spacer mt="lg" />
        <Checkbox
          label="Upgrade Transaction Limit"
          defaultChecked={upgrade_limit}
          onChange={() => allowUpgrade((c) => !c)}
          name="upgrade_limit_to_highest"
          id="upgrade_limit_to_highest"
        />
        <Spacer mt="md" />
        <Label>Agent KYC</Label>

        <Text mt="xs" color="highlight">
          {isFetching ? `KYC Level ...` : `KYC Level ${transferLimitInfo?.kyc_level}`}
        </Text>
        <ShowTree forRoles={[roles.OPPERATION_HEAD]}>
          <Button mt="lg" loading={loading(status)} type="submit" variant="success" fullWidth>
            Save
          </Button>
        </ShowTree>
      </DisableTree>
    </Box>
  )
}

export default TransactionLimitForm
