import * as React from 'react'
import { useForm } from 'react-hook-form'

/**
 * ? Local Imports
 */

import { useUpdateAgentBusinessDetails } from 'agents/hooks/useUpdateAgent'

import Box from 'design/elements/Box'
import Button from 'design/elements/Button'
import Divider from 'design/elements/Divider'

import Spacer from 'design/elements/Spacer'
import Text from 'design/elements/Text'
import TextField from 'design/elements/TextField'
import { loading } from 'lib/formatters'
import { ShowTree } from 'lib/gate'
import { roles } from 'lib/models'
import { useAuthState } from 'auth/context/Auth.Context'

const BusinessInfoDetails = ({ agent, agentId, alert, clearAlert, notify }) => {
  const form = useForm({
    mode: 'onBlur',
  })

  const { user } = useAuthState()

  const [updateBusinessDetails, { status }] = useUpdateAgentBusinessDetails({ alert, clearAlert, notify })

  const handleUpdateForm = React.useCallback(
    (_formValues) => {
      const _body = Object.assign({}, _formValues, {
        agent_id: +agentId,
        business_category: agent?.business_category,
        business_type: agent?.business_type,
        is_business_verified: agent?.is_business_verified,
      })
      updateBusinessDetails(_body)
    },
    [agent, agentId, updateBusinessDetails]
  )

  return (
    <Box as="form" onSubmit={form.handleSubmit(handleUpdateForm)}>
      <Text textTransform="uppercase" fontWeight="bold">
        Business details
      </Text>
      <Divider />
      <TextField
        label="RC (Business Registration Number)"
        name="rc_number"
        error={form.errors?.rc_number}
        inputProps={{ type: 'tel' }}
        helperText={form.errors?.rc_number?.message && 'Please enter a valid RC number'}
        ref={form.register({ required: false, minLength: 3 })}
        defaultValue={agent?.rc_number}
      />
      <Spacer mt="lg" />
      <TextField
        disabled={true}
        label="Business  Registration Type"
        name="business_type"
        defaultValue={agent?.business_type}
      />
      <Spacer mt="lg" />
      <TextField
        label="Directors/partners/Trustees "
        name="directors"
        defaultValue={agent?.directors}
        disabled={true}
      />
      <Spacer mt="lg" />
      <TextField
        label="Registered Business Name"
        name="business_name"
        error={form.errors?.business_name}
        helperText={form.errors?.business_name?.message && 'Please enter a  valid business name'}
        ref={form.register({ required: false, minLength: 3 })}
        defaultValue={agent?.business_name}
      />
      <Spacer mt="lg" />

      <TextField
        label="Sponsor Code (Optional)"
        name="sponsor_code"
        error={form.errors?.sponsor_code}
        helperText={form.errors?.sponsor_code?.message && 'Please enter a  valid Sponsor Code'}
        ref={form.register({ required: false, minLength: 3 })}
        defaultValue={agent?.recruiter_code}
        disabled={true}
      />
      <Spacer mt="lg" />
      <Text textTransform="uppercase" fontWeight="bold">
        Business address
      </Text>
      <Divider />

      <Box display="flex" justifyContent="space-between" alignItems="center">
        <TextField
          label="State"
          name="business_state"
          error={form.errors?.business_state}
          inputProps={{ type: 'text' }}
          helperText={form.errors?.business_state?.message && 'Please enter a valid State'}
          ref={form.register({ required: false, minLength: 3 })}
          defaultValue={agent?.business_state}
        />
        <TextField
          label="Local Government Area (LGA)"
          name="business_lga"
          error={form.errors?.business_lga}
          inputProps={{ type: 'text' }}
          helperText={form.errors?.business_lga?.message && 'Please enter a valid LGA'}
          ref={form.register({ required: false, minLength: 3 })}
          defaultValue={agent?.business_lga}
        />
      </Box>
      <Spacer mt="lg" />
      <TextField
        label="City"
        name="business_city"
        error={form.errors?.business_city}
        inputProps={{ type: 'text' }}
        helperText={form.errors?.business_city?.message && 'Please enter a valid City'}
        ref={form.register({ required: false, minLength: 3 })}
        defaultValue={agent?.business_city}
      />
      <Spacer mt="lg" />
      <TextField
        label="Street Name"
        name="business_address"
        error={form.errors?.business_address}
        inputProps={{ type: 'text' }}
        helperText={form.errors?.business_address?.message && 'Please enter a valid Address'}
        ref={form.register({ required: false, minLength: 3 })}
        defaultValue={agent?.business_address}
        fullWidth
      />

      <ShowTree forRoles={[roles.OPPERATION_HEAD, user?.username.toLowerCase() === 'crfmusa' && roles.OPERATIONS_ASSISTANT]}>
        <Button mt="xl" loading={loading(status)} type="submit" variant="success" fullWidth>
          Save
        </Button>
      </ShowTree>
      <Spacer mt="xl" />
    </Box>
  )
}

export default BusinessInfoDetails
